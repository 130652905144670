<template>
  <div class="sheets">
    <div class="header">
      <div class="count">
        <span>총 {{ $lib.getNumberFormat(state.data.list.length) }}개</span>
      </div>
      <div class="side">
        <select class="form-control year" v-model="state.form.year" @change="onFormValueChange()" :disabled="!state.data.loaded">
          <option v-for="y in years" :value="y">{{ y }}년</option>
        </select>
        <select class="form-control assignee" v-model="state.form.assignee" @change="onFormValueChange()" :disabled="!state.data.loaded">
          <option value="">담당 매니저</option>
          <option :value="a.name" v-for="a in state.option.assignees">{{ a.name }}</option>
        </select>
        <select class="form-control status" v-model="state.form.status" @change="onFormValueChange()" :disabled="!state.data.loaded">
          <option value="">진행 상황</option>
          <option :value="s.title" v-for="s in state.option.statuses">{{ s.title }}</option>
        </select>
        <select class="form-control funding-types" v-model="state.form.fundingType" @change="onFormValueChange()" :disabled="!state.data.loaded">
          <option value="">펀딩 방식</option>
          <option :value="t.value" v-for="t in state.option.fundingTypes">{{ t.title }}</option>
        </select>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th :data-column="c.key" v-for="c in columns">{{ c.title }}</th>
        <th>바로가기</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="p in state.data.list" :key="p.projectSeq">
        <td data-column="projectSeq">{{ p.projectSeq }}</td>
        <td data-column="managerMemberName">
          <a :href="`/channel/${p.managerMemberSeq}`" target="_blank" class="ellipsis color-anchor" title="해당 채널로 이동하시려면 클릭하세요.">{{ p.managerMemberName }}</a>
        </td>
        <td data-column="category">{{ p.category }}</td>
        <td data-column="investStartDate">{{ p.investStartDate }}</td>
        <td data-column="investEndDate">{{ p.investEndDate }}</td>
        <td data-column="duration">{{ p.duration }}</td>
        <td data-column="fundingType">{{ getFundingTypeTitle(p.fundingType) }}</td>
        <td data-column="calcDate">
          <Date :componentNameSuffix="p.projectSeq" :value.sync="p.calcDate" :func="() => onInputChange(p, 'calcDate')" :disabled="!state.data.loaded"/>
        </td>
        <td data-column="status">
          <select v-model="p.status" @change="onInputChange(p, 'status', $event)" :disabled="!state.data.loaded">
            <option value="" :selected="!p.status"></option>
            <option :value="s.title" v-for="s in state.option.statuses">{{ s.title }}</option>
          </select>
        </td>
        <td data-column="assignedManager">
          <div class="ellipsis" v-if="p.assignedManager" :title="p.assignedManager">{{ p.assignedManager }}</div>
        </td>
        <td>
          <a :href="getProjectUrl(p)" target="_blank" class="color-anchor" title="해당 프로젝트 페이지로 이동하시려면 클릭하세요.">바로가기</a>
        </td>
      </tr>
      </tbody>
      <tbody v-if="state.data.loaded && !state.data.list.length">
      <tr>
        <td :colspan="columns.length + 1" class="text-center">데이터가 없습니다.</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import InvestProjectPauseNotice from "@/modals/InvestProjectPauseNotice";
import http from "@/scripts/http";
import router from "@/scripts/router";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import Date from "@/components/Date.vue";

function Component(initialize) {
  this.name = "pageAdminRewardProjectSheets";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Date, InvestProjectPauseNotice},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const years = [];
    const currentYear = new window.Date().getFullYear();

    const columns = [
      {key: "projectSeq", title: "번호"},
      {key: "managerMemberName", title: "진행자"},
      {key: "category", title: "분야"},
      {key: "investStartDate", title: "오픈일"},
      {key: "investEndDate", title: "종료일"},
      {key: "duration", title: "기간"},
      {key: "fundingType", title: "방식"},
      {key: "calcDate", title: "정산일",},
      {key: "status", title: "진행 상황"},
      {key: "assignedManager", title: "담당자"}
    ];

    const state = reactive({
      form: {
        year: currentYear,
        assignee: "",
        status: "",
        fundingType: "",
      },
      data: {
        loaded: false,
        list: [],
        prev: {},
      },
      option: {
        assignees: [
          {name: "한송이"},
          {name: "안가연"},
          {name: "조은성"},
        ],
        statuses: [
          {title: "오픈"},
          {title: "펀딩 종료"},
          {title: "실패"},
          {title: "정산서 발송"},
          {title: "정산 완료"},
          {title: "진행 포기"},
          {title: "신청 대기"},
          {title: "보완 요청"},
        ],
        fundingTypes: [
          {title: "AON", value: "A"},
          {title: "KIA", value: "K"},
        ]
      }
    });

    const onInputChange = (project, key, e) => {
      const projectSeq = project.projectSeq;
      const value = project[key];
      const preValue = state.data.prev[projectSeq][key];
      const columnTitle = columns.find(c => c.key === key)?.title;
      const particle = lib.hasFinalConsonant(value) ? "으로" : "로"; // 조사
      const messagePrefix = `선택하신 프로젝트의 [${columnTitle}] 값을 ` + (value ? `"${value}"${particle}` : "빈 값으로");

      const rollback = () => (project[key] = preValue);

      if (value === preValue) {
        return rollback(); // 공백 입력 대비
      } else if (!window.confirm(`${messagePrefix} 수정할까요?`)) {
        return rollback();
      }

      http.put(`/api/admin/reward/sheet/projects/${projectSeq}`, {key, value}).then(() => {
        e?.target?.tagName === "SELECT" && e.target.blur();
        store.commit("setSwingMessage", `${messagePrefix} 수정했습니다.`);
        load();
      }).catch((e) => {
        console.error(e);
        window.alert("오류가 있습니다. 다시 시도해주세요.");
        rollback();
      });
    };

    const onFormValueChange = () => {
      const query = lib.getRenewed(state.form);

      for (let i in query) {
        !query[i] && (delete query[i]);
      }

      router.push({query});
    };

    for (let i = 1; i >= -5; i--) {
      years.push(currentYear + i);
    }

    const getProjectUrl = (project) => {
      return `/reward/${project.projectSeq}${project.openFlag === "Y" ? "" : "/preview"}`;
    };

    const getFundingTypeTitle = (fundingTypeValue) => {
      return state.option.fundingTypes.find(t => t.value === fundingTypeValue)?.title || "";
    };

    const setForm = () => {
      const yearFromQuery = router.app.$route.query.year;
      state.form.year = yearFromQuery ? Number.parseInt(yearFromQuery.toString()) : currentYear;
      state.form.assignee = router.app.$route.query.assignee?.toString() || "";
      state.form.status = router.app.$route.query.status?.toString() || "";
      state.form.fundingType = router.app.$route.query.fundingType?.toString() || "";
    };

    const load = async () => {
      setForm();

      state.data.loaded = false;
      http.get(`/api/admin/reward/sheet/projects`, state.form).then((res1) => {
        state.data.loaded = true;
        state.data.list = res1.data.body;

        for (const p of state.data.list) {
          state.data.prev[p.projectSeq] = {
            calcDate: p.calcDate,
            status: p.status,
          };
        }
      });
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (next.modals || prev.modals) {
        return;
      }

      load();
    });

    return {component, state, columns, years, getProjectUrl, getFundingTypeTitle, onInputChange, onFormValueChange};
  }
});
</script>

<style lang="scss" scoped>
.sheets {
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $px15;

    .count {
      align-self: flex-end;
    }

    .side {
      display: flex;
      gap: $px10;

      select {
        font-size: $px14;
        width: $px94;

        &.year {
          width: $px87;
        }

        &.assignee {
          width: $px107;
        }
      }
    }
  }

  table {
    margin-bottom: $px25;

    tr {
      th, td {
        padding-left: $px10;
        padding-right: $px10;
        font-size: $px12;
        vertical-align: middle;

        &[data-column="status"] {
          padding: 0;
        }

        &[data-column="calcDate"] {
          padding-top: 0;
          padding-bottom: 0;
          max-width: $px90;
        }

        &[data-column="assignedManager"] {
          max-width: $px100;
        }

        &[data-column="managerMemberName"] {
          max-width: $px120;
        }
      }
    }

    thead tr th {
      background: #f8f9fa;
      border-bottom: 0;
    }

    tbody tr td {
      select {
        border: $px1 solid $colorBorder;
        padding: $px7 $px20 $px7 $px7;
        background-position-x: 100%;
        border-radius: $px4;
        height: $px34;
      }

      &::v-deep {
        input[type=text] {
          background-color: transparent;
          border: $px1 solid $colorBorder;
          padding: $px7;
          height: $px34;
          color: inherit;
        }
      }

      &[data-column="managerMemberName"] {
        a {
          display: inline-block;
          max-width: 100%;
        }
      }
    }
  }
}
</style>